<template>

    <div>
    <hb-modal v-model="dialog" show-help-link size="large" :title="selected && selected.id ? (isOPS? 'Edit Coverage Plan' : 'Edit Insurance') :  (isOPS ? 'Add Coverage Plan' : 'Add Insurance')" @close="$emit('close')">
        <template v-slot:content>
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            <v-row class="ma-0 pa-0">
                <v-col md="4" class="key-label pl-6">
                    <div class="pt-2 font-15px">Name</div>
                </v-col>
                <v-col md="8 pl-5 pr-7 pa-2">
                    <div class="form-input-container">
                        <v-text-field
                            :readonly="isPropertyLevel"
                            dense
                            solo
                            flat
                            :hide-details="!errors.has('name')"
                            v-model="insurance.name"
                            v-validate="'required|max:45'"
                            id="name"
                            name="name"
                            :error-messages="errors.first('name')"
                            placeholder="Enter Name">
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-divider v-if="!isPropertyLevel"></v-divider>
            <v-row class="ma-0 pa-0" v-if="!isPropertyLevel">
                <v-col md="4" class="key-label pl-6">
                    <div class="pt-2 font-15px">Description</div>
                </v-col>
                <v-col md="8 pl-5 pr-7 pa-2">
                    <div class="form-input-container">
                        <v-textarea
                            dense
                            solo
                            flat
                            :hide-details="!errors.has('description')"
                            placeholder="Enter a description"
                            rows="2"
                            v-model="insurance.description"
                            v-validate="'max:1000'"
                            id="description"
                            name="description"
                            class="pa-0 mt-0"
                            :error-messages="errors.first('description')"
                        > </v-textarea>
                    </div>
                </v-col>
            </v-row>
            <v-divider v-if="!isPropertyLevel"></v-divider>
            <v-row class="ma-0 pa-0" v-if="!isPropertyLevel">
                <v-col md="4" class="key-label pl-6">
                    <div class="pt-2 font-15px">Space Type</div>
                </v-col>
                <v-col md="8 pl-9 pr-7 pa-2">
                    <div class="form-input-container" :class="{'has-error': errors.has('unit_type_id') }">
                        <v-select
                            :items="getUnitTypes('have_coverage')"
                            hide-details
                            single-line
                            attach
                            label="Select Space Type"
                            v-validate="'required'"
                            id="unit_type_id"
                            name="unit_type_id"
                            data-vv-as="space type"
                            v-model="insurance.unit_type_id"
                            :error-messages="errors.first('unit_type_id')"
                            :hide-details="!errors.has('unit_type_id')"
                            item-text="display_name"
                            item-value="unit_type_id"
                        >
                        </v-select>
                    </div>
                </v-col>
            </v-row>
            <v-divider v-if="!isPropertyLevel"></v-divider>
            <v-row class="ma-0 pa-0" v-if="!isPropertyLevel">
                <v-col md="4" class="key-label pl-6">
                    <div v-if="!isOPS" class="pt-2 font-15px">Monthly Premium</div>
                     <div v-if="isOPS" class="pt-2 font-15px">Monthly Cost/Premium</div>
                </v-col>
                <v-col md="6 pl-9 pa-2 pb-3">
                    <div class="form-input-container">
                        <v-text-field
                            dense
                            :hide-details="!errors.has('premium_value') && !errors.has('premium_type')"
                            v-model="insurance.premium_value"
                            v-validate="'required|decimal:2|min_value:.01|max_value:9999'"
                            name="premium_value"
                            id="premium_value"
                            data-vv-as="premium value"
                            class="pa-0 mt-0"
                            placeholder="Enter Monthly Premium"
                            :error-messages="errors.first('premium_value') || errors.first('premium_type')"
                        >
                        </v-text-field>
                    </div>
                </v-col>
                <v-col md="1 pl-0 py-2">
                    <div class="form-input-container">
                        <v-select
                            dense
                            hide-details
                            attach
                            class="pt-0 mt-0 select-items"
                            v-model="insurance.premium_type"
                            :items="premiumTypes"
                            name="premium_type"
                            id="premium_type"
                        >
                        </v-select>
                    </div>
                </v-col>
            </v-row>
            <v-divider v-if="!isPropertyLevel"></v-divider>
            <v-row class="ma-0 pa-0" v-if="!isPropertyLevel">
                <v-col md="4" class="key-label pl-6">
                    <div class="pt-2 font-15px">Coverage</div>
                </v-col>
                <v-col md="8 pl-4 pr-7 pa-2">
                    <div class="form-input-container">
                        <v-text-field
                            solo
                            flat
                            dense
                            :hide-details="!errors.has('coverage')"
                            v-model="insurance.coverage"
                            data-vv-as="coverage"
                            v-validate="'required|decimal:2|min_value:.01|max_value:999999'"
                            id="coverage"
                            name="coverage"
                            placeholder="Coverage"
                            class="pa-0 mt-0 insurance-input"
                            :error-messages="errors.first('coverage')"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon>mdi-currency-usd</v-icon>
                            </template>
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-divider v-if="!isPropertyLevel"></v-divider>
            <v-row class="ma-0 pa-0" v-if="!isPropertyLevel">
                <v-col md="4" class="key-label pl-6">
                    <div class="pt-2 font-15px">Deductible</div>
                </v-col>
                <v-col md="8 pl-7 pr-7 pa-2 pb-3">
                    <div class="form-input-container">
                        <v-text-field
                            hide-details
                            dense
                            v-model="insurance.deductible"
                            v-validate="'decimal:2|min_value:0|max_value:99999'"
                            name="deductible"
                            id="deductible"
                            class="insurance-input"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon class="pt-1">mdi-currency-usd</v-icon>
                            </template>
                        </v-text-field>
                        <span v-show="errors.has('deductible')" class="status-block error-block field-error pt-2">{{ errors.first('deductible') }}</span>
                    </div>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0">
                <v-col md="4" class="key-label pl-6 pt-4">
                    <div class="font-15px">Taxable</div>
                </v-col>
                <v-col md="8 pl-7 pr-7 pa-2">
                  <div class="form-input-container pb-2">
                      <v-radio-group v-model="insurance.taxable" row hide-details class="mt-2 pt-0">
                        <v-radio label="Yes" :value="1"></v-radio>
                        <v-radio class="pl-3" label="No" :value="0"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0">
                <v-col md="4" class="key-label pl-6 pt-4">
                    <div class="font-15px">Prorate In</div>
                </v-col>
                <v-col md="8 pl-7 pr-7 pa-2">
                  <div class="form-input-container pb-2">
                      <v-radio-group v-model="insurance.prorate" row hide-details class="mt-2 pt-0">
                        <v-radio label="Yes" :value="1"></v-radio>
                        <v-radio class="pl-3" label="No" :value="0"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
            </v-row>
 
            <v-row class="top-border ma-0 pa-0">
                <v-col md="4" class="key-label pl-6 pt-4">
                    <div class="font-15px">Prorate Out</div>
                </v-col>
                <v-col md="8 pl-7 pr-7 pa-2">
                  <div class="form-input-container pb-2">
                      <v-radio-group v-model="insurance.prorate_out" row hide-details class="mt-2 pt-0">
                        <v-radio label="Yes" :value="1"></v-radio>
                        <v-radio class="pl-3" label="No" :value="0"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0" v-if="available_accounts.length > 0 && !isOPS">
                <v-col md="4" class="key-label pl-6 pt-4 pb-0">
                    <div class="font-15px">GL Account</div>
                </v-col>
                <v-col md="8 pl-5 pr-7 pa-2 pb-0">
                    <div class="form-input-container pb-2">
                        <hb-autocomplete
                            :disabled="!hasPermission('assign_gl_accounts')"
                            name="gl_account_id"
                            hide-details
                            label="Select an Account"
                            :items="available_accounts"
                            :clearable="false"
                            v-validate="`${incomeAccountRequired ?  'required' : ''}`"
                            :error-messages="errors.first('gl_account_id')? 'Income account is required as accounting service is enabled' : ''"
                            data-vv-as="gl account"
                            item-value="id"
                            item-text="name"
                            v-model="insurance.income_account_id">
                            <template v-slot:selection="{ item }">
                                {{item.code}} : {{item.name}}
                            </template>
                            <template v-slot:item="{ item }">
                                {{item.code}} : {{item.name}}
                            </template>
                        </hb-autocomplete>
                    </div>

                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0" v-if="!isPropertyLevel && !isOPS">
                <v-col md="4" class="key-label pl-6 pt-4">
                    <div class="font-15px">Enable Properties</div>
                </v-col>
                <v-col md="8" class="pa-2 pl-6 pr-6 scrollable-div">
                    <div class="form-input-container overflow-text" v-for="(item, index) in propertiesList" :key="index">
                        <v-checkbox
                            hide-details
                            :label="item.name"
                            v-model="productProperties"
                            :value="item.id"
                            :class="{ 'mt-2' : index == 0 }"
                        />
                    </div>
                </v-col>
            </v-row>
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" @click="save">
                <span v-if="selected && selected.id">Save</span>
                <span v-else>Add</span>
            </hb-btn>
            <span v-show="isLoading($options.name)" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
        </template>
    </hb-modal>

    <income-account-alert
    v-if="showAlertDialog && !isPropertyLevel"
    v-model="showAlertDialog"
    @confirm="confirmAlert"
    @close="showAlertDialog = false">
    </income-account-alert>
    </div>

</template>


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import IncomeAccountAlert from './IncomeAccountAlertModal/IncomeAccountAlert.vue'

    import Dropdown from '../assets/Dropdown.vue';
    import { mapGetters , mapActions } from 'vuex';

    import { notificationMixin } from "../../mixins/notificationMixin.js";

    export default {

        name: "EditInsurance",
        mixins:[notificationMixin],
        data() {
            return {
                open: '',
                insurance:{
                    id: '',
                    coverage: '',
                    deductible: '',
                    premium_value: '',
                    premium_type: '$',
                    recurring: 1,
                    prorate: 0,
                    prorate_out: 0,
                    name:'',
                    description: '',
                    taxable: 0,
                    unit_type_id:'0'
                },
                premiumTypes: ['$','%'],
                isPropertyLevel : false,
                productProperties: [],
                accounts: [],
                showAlertDialog: false,
                alertConfirmed : false,
            }
        },
        components:{
            Loader,
            Status,
            Dropdown,
            IncomeAccountAlert
        },
        props:[
            'selected',
            'facilityID',
            'productLevel',
            'value',
            'isOPS'
        ],
        async created(){

            if ((this.productLevel && this.productLevel == 'Property') || this.facilityID) {
                this.isPropertyLevel = true;
                if (this.isOPS && this.productLevel == 'Property' && !this.selected) {
                        this.isPropertyLevel = false;
                    }
            }
            if(this.selected && this.selected.id){
                this.insurance = JSON.parse(JSON.stringify(this.selected));
                this.productProperties = this.insurance.Properties ? this.insurance.Properties.map(val => val.id) : [];
            } 

            await this.fetchAccounting();
            // await this.fetchAccountingType();
            await this.getAccountingType();
            // await this.getAccountingType({ isInsurance : true })

        },
        computed:{
            ...mapGetters({
                propertiesList: 'propertiesStore/filtered',
                onboardingData: 'onboardingTabsStore/getOnboardingData',
                hasPermission: 'authenticationStore/rolePermission',
                incomeAccountRequired: 'accountingStore/incomeAccountRequired',
                recordCountWithNullIncomeAccount: 'accountingStore/recordCountWithNullIncomeAccount',
            }),
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
            income_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.category.toLowerCase() === 'income');
            },
            expense_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.category.toLowerCase() === 'expense');
            },
            liability_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.category.toLowerCase() === 'liability');
            },
            concession_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.account_subtype.toLowerCase() === 'concessions');
            },
            available_accounts(){
              return [...this.income_accounts,...this.expense_accounts,...this.liability_accounts];
            },
        },
        methods:{
            ...mapActions({
                getAccountingType : 'accountingStore/getAccountingType',
            }),

            confirmAlert() {
                this.alertConfirmed = true;
                this.showAlertDialog = false;
                this.save()
            },

           

            async save(){

	            this.validate(this).then(status => {
			        if(!status) return;

                    if(!this.isLoading(this.$options.name)){

                        this.errorClear(this.$options.name);
                        this.startLoading(this.$options.name);

                        let data = Object.assign({}, this.insurance);
                        data.Properties = this.productProperties && this.productProperties.map(val => {
                            return {id: val};
                        });

                        let propertyLevelData = {
                            taxable: this.insurance.taxable,
                            recurring: this.insurance.recurring,
                            prorate: this.insurance.prorate,
                            prorate_out: this.insurance.prorate_out,
                            income_account_id: this.insurance.income_account_id
                        }

                        // added by BCT
                        if(this.isOPS && this.onboardingData.propertyData.length > 0 && !this.selected){
                            data.Properties = [{id: this.onboardingData.propertyData[0].property_id}] 
                        }

                        if(this.insurance.id){
                            let path = this.isPropertyLevel ? api.PROPERTIES + this.facilityID + '/products/' + this.insurance.id : api.INSURANCE + this.insurance.id;
                            api.put(this, path , this.isPropertyLevel ? propertyLevelData : data).then(r => {
                                this.$emit('refetch');
                                this.$emit('close');
                                this.$emit('showSuccess', this.insurance.name, true);
                            }).catch(error => {
                                this.showMessageNotification({ description: error });
                            });
                        } else {
                            api.post(this, api.INSURANCE, data).then(r => {
                                this.$emit('refetch');
                                this.$emit('close');
                                this.$emit('showSuccess', this.insurance.name);
                            }).catch(error => {
                                this.showMessageNotification({ description: error });
                            });

                        }

                    }
                })

            },
            away(){
                return this.openSuggestion = false;
            },
            async fetchAccounting(){
                let p = await api.get(this, api.ACCOUNTING + 'accounts');
                this.accounts = p.accounts || [];
            }
        },

    }


</script>

<style scoped>
    .mrl--25px{
        margin-left: -24px;
        margin-right: -24px;
    }
    .key-label{
      background: #F9FAFB;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .simple-error{
        border: 1px solid #D91E18;
    }
    @media (max-height : 775px) {
        .scroll-able-div{
            max-height: 500px;
            overflow: scroll;
        }
    }
    .overflow-text {
        overflow-wrap: anywhere;
    }
    .scrollable-div{
        max-height: 300px;
        overflow: auto;
    }
</style>

<style>
    .select-items.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
        padding: 0 4px;
    }
    .insurance-input .theme--light.v-icon {
        color: rgba(0, 0, 0, 0.54);
    }
</style>
